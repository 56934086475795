
export default [

  { id: "1", icon: "home", title: "홈", to: "/starter" },
  { id: "2", icon: "star", title: "세대 정보", to: "/unit" },
  { id: "3", icon: "user", title: "세대 인증", to: "/unitPending" },
  { id: "4", icon: "list", title: "공지사항", to: "/notice" },
  { id: "5", icon: "share", title: "주민투표", to: "/vote" },
  { id: "6", icon: "truck", title: "민원/하자", to: "/request" },
  { id: "7", icon: "clock", title: "시설예약", to: "/place" },
  { id: "8", icon: "navigation", title: "방문차량", to: "/carVisit" },
  { id: "9", icon: "clipboard", title: "관리비", to: "/notReady" },
  { id: "10", icon: "crosshair", title: "원격검침", to: "/notReady" },

];
