<template>
  <header class="topbar">
    <b-navbar
      toggleable="lg"
      :type="`${navbarColor == 'white' ? 'light' : 'dark'}`"
      class="p-0"
      :variant="navbarColor"
    >
      <b-navbar-nav
        ><b-nav-item @click="showMobileSidebar" class="d-block d-lg-none"
          ><i class="mdi mdi-menu fs-3"></i></b-nav-item
      ></b-navbar-nav>
      <div :class="`navbar-header d-block d-lg-none`">
        <LogoDark v-if="navbarColor == 'white'" />
        <LogoLight v-else />
      </div>
      <b-navbar-toggle target="nav-collapse" class="mx-2 border-0"
        ><i class="ti-more"></i
      ></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="">
        <!-- -----------------------------------------------------------
          Left aligned nav items
        ----------------------------------------------------------- -->
        <b-navbar-nav>
          <!-- -----------------------------------------------------------
            Toggle Sidebar
          ----------------------------------------------------------- -->
          <b-nav-item @click="setMiniSidebar" class="d-none d-lg-block"
            ><i class="mdi mdi-menu fs-3"></i
          ></b-nav-item>

        </b-navbar-nav>

        <!-- -----------------------------------------------------------
          Right aligned nav items
        ----------------------------------------------------------- -->
        <b-navbar-nav class="ml-auto">
          <!-- -----------------------------------------------------------
            Profile Dropdown
          ----------------------------------------------------------- -->
          <b-nav-item-dropdown right no-caret class="mailbox">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <img
                src="../../../assets/images/users/profile_new.jpeg"
                alt="user"
                class="rounded-circle"
                width="31"
              />
            </template>
            <b-dropdown-text class="bg-primary text-white">
              <div class="d-flex align-items-center py-2">
                <b-img
                  src="@/assets/images/users/profile_new.jpeg"
                  width="50"
                  rounded="circle"
                />
                <div class="ml-3">
                  <h4 class="mb-0">{{ UserName }}</h4>
                  <span class="font-weight-light">{{ AptName }}</span>
                </div>
              </div>
            </b-dropdown-text>
            <b-dropdown-item @click="settings" class="d-flex align-items-center">
              <feather type="settings" class="feather-sm mr-2 mb-n1"></feather>
              환경설정
            </b-dropdown-item>
            <b-dropdown-item @click="logout" class="d-flex align-items-center">
              <feather type="log-out" class="feather-sm mr-2 mb-n1"></feather>
              로그아웃
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import { mapState } from "vuex";
import LogoDark from "../logo-dark/LogoDark";
import LogoLight from "../logo-light/LogoLight";

export default {
  name: "vertical-header",
  data: () => ({
    showToggle: false,
    href() {
      return undefined;
    },
  }),
  components: {
    LogoDark,
    LogoLight,
  },
  computed: {
    ...mapState(["navbarColor", "logoColor", "LayoutType", "AptName", "UserName"]),
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == "en") return { lang: "flag-icon flag-icon-us" };
      else if (locale == "fr") return { lang: "flag-icon flag-icon-fr" };
      else if (locale == "ch") return { lang: "flag-icon flag-icon-ch" };
      else if (locale == "gr") return { lang: "flag-icon flag-icon-gr" };
      return this.locale;
    },
  },
  methods: {
    settings(){
      this.$router.push("/settings");
    },
    async logout(){
      await localStorage.removeItem("ACCESS_TOKEN");
      await localStorage.removeItem("REFRESH_TOKEN");
      this.$router.replace("/login");
    },
    showMobileSidebar() {
      this.$store.commit("SET_SIDEBAR_DRAWER", true);
    },
    setMiniSidebar() {
      if (this.LayoutType == "full-sidebar") {
        this.$store.dispatch("setLayoutType", "mini-sidebar");
      } else if (this.LayoutType == "mini-sidebar") {
        this.$store.dispatch("setLayoutType", "full-sidebar");
      }
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>
